<template>
  <UILoading v-if="loading || loadingVergelijk"></UILoading>
  <div v-else-if="statistieken" class="flex flex-col gap-8">
    <small class="-mb-4">Contacten zijn klant gegevens (inclusief sollicitaties, contactformulier, reservaties, ... )</small>
    <div class="card">
      <h2>Contacten - Aantal</h2>
      <div class="grid xl:grid-cols-4 gap-4">
        <BamTarget
          v-for="timeframe in Object.keys(statistieken)"
          :key="timeframe"
          :title="capitalize(timeframe)"
          :value="statistieken[timeframe].aantal_ritten"
        >
          <template v-slot:prefix>
            <ul>
              <li class="flex flex-row justify-between">
                <span>{{ statistieken[timeframe].nieuw + statistieken[timeframe].terugkerend }} Contacten</span>
                <PercentageChange v-if="props.filtersVergelijken" :value="statistieken[timeframe].nieuw + statistieken[timeframe].terugkerend" :previous-value="statistiekenVergelijk[timeframe].nieuw + statistiekenVergelijk[timeframe].terugkerend" />
              </li>
            </ul>
          </template>
          <template v-slot:suffix>
            <ul class="pt-2 border-t border-dashboard-dark">
              <li class="flex flex-row justify-between">
                <span>{{ statistieken[timeframe].nieuw }} Nieuw</span>
                <PercentageChange v-if="props.filtersVergelijken" :value="statistieken[timeframe].nieuw" :previous-value="statistiekenVergelijk[timeframe].nieuw" />
              </li>
              <li class="flex flex-row justify-between">
                <span>{{ statistieken[timeframe].terugkerend }} Terugkerend</span>
                <PercentageChange v-if="props.filtersVergelijken" :value="statistieken[timeframe].terugkerend" :previous-value="statistiekenVergelijk[timeframe].terugkerend" />
              </li>
            </ul>
          </template>
        </BamTarget>
      </div>
    </div>

    <div class="card">
      <h2>Nieuwe contacten - Maand overzicht</h2>
      <div>
        <BarStatsMulti
          :colors="['#008ffb', '#B0C4DE']"
          :values="[
            Object.values(grafiek.daily).map(el => el.nieuw || 0),
            props.filtersVergelijken && Object.values(grafiekVergelijk.daily).map(el => el.nieuw || 0),
          ].filter(el => el?.length)"
          :labels="[
            'Nieuwe Contacten',
            props.filtersVergelijken && 'Nieuwe Contacten (Vergelijken)',
          ].filter(el => el?.length)"
        ></BarStatsMulti>
      </div>
    </div>

    <div class="card">
      <h2>Terugkerende contacten - Maand overzicht</h2>
      <div>
        <BarStatsMulti
          :colors="['#008ffb', '#B0C4DE']"
          :values="[
            Object.values(grafiek.daily).map(el => el.terugkerend || 0),
            props.filtersVergelijken && Object.values(grafiekVergelijk.daily).map(el => el.terugkerend || 0),
          ].filter(el => el?.length)"
          :labels="[
            'Terugkerende Contacten',
            props.filtersVergelijken && 'Terugkerende Contacten (Vergelijken)',
          ].filter(el => el?.length)"
        ></BarStatsMulti>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent} from 'vue'
import { capitalize } from '@/functions/formatText'

import useGetApi from '@/hooks/useGetApi.js'
import useRenderKey from '@/hooks/useRenderKey.js'

import UILoading from '@/components/UI/Loading.vue'
import BamTarget from '@/components/Charts/BamTarget.vue'

const BarStatsMulti = defineAsyncComponent(() => import('@/components/Charts/BarStatsMulti.vue'))
const PercentageChange = defineAsyncComponent(() => import('@/components/UI/Labels/PercentageChange.vue'))

const props = defineProps({
  filters: Object,
  filtersVergelijken: Object,
  renderKey: [String, Number],
})

useRenderKey(props.renderKey)
const { data, loading } = useGetApi('STATS_CONTACTEN', props.filters, { watch: true })
const { data: dataVergelijk, loading: loadingVergelijk } = useGetApi('STATS_CONTACTEN', props.filtersVergelijken, { watch: true, skipMounted: !props.filtersVergelijken })

const statistieken = computed(() => data.value?.statistieken || {})
const grafiek = computed(() => data.value?.grafiek || { daily: {}, monthly: {}, yearly: {} })

const statistiekenVergelijk = computed(() => dataVergelijk.value?.statistieken || {})
const grafiekVergelijk = computed(() => dataVergelijk.value?.grafiek || { daily: {}, monthly: {}, yearly: {} })
</script>
