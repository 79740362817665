export default (_value = 0, _vergelijkValue = 0) => {
  const value = Number(_value)
  const vergelijkValue = Number(_vergelijkValue)

  if (typeof vergelijkValue === 'undefined') {
    return '??'
  }

  if (vergelijkValue === value) {
    return 0
  }

  if (value === 0 && vergelijkValue > 0) {
    return -100
  }

  if (vergelijkValue === 0 && value > 0) {
    return 100
  }


  const increase = value - vergelijkValue
  return Number((increase / vergelijkValue * 100).toFixed(2))
}
