<template>
  <div>
    <apexchart height="100%" width="100%" type="radialBar" :options="options" :series="series"></apexchart>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  value: {
    type: [Number, String],
    default: 50,
  },
  size: {
    type: [Number, String],
    default: 300,
  },
})

const series = computed(() => [props.value > 0 ? props.value : 0])
const safeSize = computed(() => Math.max(200, props.size))

const options = computed(() => ({
  chart: {
    height: safeSize.value,
    type: 'radialBar',
    redrawOnParentResize: false,
    background: '##d2d3d4',
  },
  grid: {
    padding: {
      top: -10,
      bottom: -10,
      left: -10,
      right: -10,
    },
  },
  fill: {
    type: 'solid',
    colors: [
      function({ value }) {
        if (value < 50) return '#eb3838'
        if (value >= 50 && value < 80) return '#eb8338'
        return '#b1c94e'
      },
    ],
  },
  plotOptions: {
    radialBar: {
      dataLabels: {
        show: true,
        name: {
          show: false,
        },
        value: {
          show: true,
          offsetY: 6,
          fontSize: '18px',
          color: '#222',
          // offsetY: 0,
          formatter: function(val) {
            return `${Number(val).toFixed(2)} %`
          },
        },
      },
    },
  },
  labels: ['BAM'],
}))
</script>
