
import { watch, getCurrentInstance } from 'vue'

export default (renderKey) => {
  watch(() => renderKey, (val, oldval) => {
    if (val == oldval) return
    const instance = getCurrentInstance()
    instance.forceUpdate()
  })
}
