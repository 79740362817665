<template>
  <span v-show="!changeIsNaN" class="font-bold" :class="classes">
    {{ prefix }}{{ suffix }}&nbsp;% <span v-if="typeof previousValue !== 'undefined'">({{ formatPrice(previousValue) }})</span>
  </span>
</template>

<script setup>
import { computed } from 'vue'
import { formatPrice } from '@/functions/formatNumber'
import usePercentageIncrease from '@/hooks/usePercentageIncrease'

const props = defineProps({
  change: [String, Number],
  value: [String, Number],
  previousValue: [String, Number],
  reverse: Boolean,
})

const innerChange = computed(() => {
  if (props.change) return Number(props.change)
  return usePercentageIncrease(props.value || 0, props.previousValue || 0)
})

const prefix = computed(() => {
  if (innerChange.value === '??') return ''
  return innerChange.value >= 0 ? '+' : '-'
})

const suffix = computed(() => {
  if (innerChange.value === '??') return '??'
  return Math.abs(Number(innerChange.value)).toFixed(2)
})

const changeIsNaN = computed(() => innerChange.value != 0 && innerChange.value != '??' && Number.isNaN(Number(innerChange.value || NaN)))

const classes = computed(() => ({
  'text-red-500': props.reverse ? innerChange.value >= 0 : innerChange.value < 0,
  'text-green-500': props.reverse ? innerChange.value < 0 : innerChange.value >= 0,
  'text-gray-400': innerChange.value == '??',
}))
</script>
